.PushSend {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
  }

  .preview {
    flex-basis: 33%;
    border-right: 1px solid #caced8;

    .section-content {
      position: sticky;
      top: 20px;
    }
  }

  .phone {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 20px;
    width: 320px;
    height: 600px;
    padding: 0 10px 20px;
    background-color: #fff;
  }

  .phone-speaker {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    div {
      border: 2px solid black;
      width: 40px;
      border-radius: 10px;
    }
  }

  .phone-display {
    display: flex;
    position: relative;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: start;
    width: 96%;
    height: 90%;
    border: 1px solid black;
    padding: 0px;
    background-color: #fff;
    word-wrap: break-word;
    line-height: 20px;
    font-size: 14px;
  }

  .phone-display-background {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: fill;
    align-items: center;
  }

  .phone-message {
    border-radius: 15px;    
    z-index: 10;
    width: 90%;
    min-height: 80px;
    color: #fff;
    padding: 10px 15px;
    background-color: rgba(231, 211, 211, 0.738);
    box-sizing: border-box;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-size: 14px;

    &-header {      
      display: flex;
      align-items: center;
      height: 20px;

      &-appname {         
        flex: 1;
        color: #444;   
      }

      &-time {
        color: #444;
        font-size: 13px;
      }

      &-icon {
        width: 20px;
        margin-right: 10px;
      }
    }  
    
    &-title {
      margin-top: 5px;      
      font-weight: bold;
      color: #333;
    }

    &-body {
      margin-top: 2px;   
      color: #444;   
    }
   
  }

  .phone-message-icon {
    width: 20px;
    float: left;
    margin-right: 10px;
    align-content: center;
  }

  .phone-message-link {
    font-size: 10px;
    text-decoration: underline;
    color: #fff;
  }
  .message {
    flex-grow: 1;
  }

  .write-message {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .recipients {
    width: 100%;

    .channel-list {
      width: 100%;
    }
  }
}
