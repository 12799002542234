.Snackbar {
  min-width: 500px;
  display: flex;
  position: absolute;
  bottom: 0px;
  height: 50px;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: #333c46;
  color: #fff;
}
