.Sidebar {
  display: flex;
  flex-direction: column;
  width: 250px;
  background-color: #2b333e;
  transition: 1s;
  color: #ffffff99;
  justify-content: space-between;
  flex-shrink: 0;

  header,
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 70px;
    background-color: #333c47;
  }

  header h1 {
    font-size: 30px;
  }

  .menu {
    flex-grow: 1;
    font-size: 14px;
  }

  .menu-section {
    display: block;
    border-left: 3px solid transparent;
    padding: 10px;

    &.active {
      border-left: 3px solid #00a9ff;
    }

    &.admin-menu {
      display: none;
      &.visible {
        display: block;
      }
    }
  }

  .menu-heading {
    display: block;
    padding: 10px;
    color: #7b7f85;
  }

  .menu-items {
    padding: 0 10px;
    list-style: none;
    margin: 0;
  }

  .menu-item {
    display: block;
    padding: 10px;
    color: #7b7f85;
    /*#c5cfde*/
  }

  .active-link {
    color: #fff;
  }

  footer {
    cursor: pointer;

    .email {
      color: #d6d8da;
      &:hover {
        color: #fff;
      }
    }

    .user-menu {
      padding: 10px 0;
      display: none;
      width: 220px;
      position: absolute;
      bottom: 65px;
      margin: 0;
      &.visible {
        display: block;
      }

      .user-menu-li a {
        display: block;
        padding: 10px;
      }
    }

    .arrow {
      position: absolute;
      bottom: -30px;
      left: calc(50% - 20px);
      height: 0;
      width: 0;
      border: 20px solid transparent;
      border-top: 10px solid #fff;
    }
  }
}
