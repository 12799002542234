.App {
  display: flex;
  height: 100%;

  .App-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 30px;
    color: #455867;
  }

  // global classes.
  .hidden {
    display: none;
  }
}

.infobox {
  display: flex;
  background-color: white;
  padding: 20px;
  border: 1px solid #e7e9ed;
  border-radius: 5px;
  width: 80%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

// Override ReactTable styles
// https://react-table.js.org/
.PD-table.ReactTable {
  height: 100%;
  border: 1px solid #e7e9ed;
  border-radius: 6px;
  background-color: #fff;

  .rt-thead {
    flex-grow: 0;
    .rt-th {
      padding: 10px 15px;
    }
    .rt-tr {
      text-align: left;
    }
  }
  .rt-tbody {
    flex-grow: 0;
  }
  .rt-tr {
    min-height: 40px;
  }
  .rt-tr-group {
    min-height: 40px;
    cursor: pointer;
  }
  .rt-td {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}
