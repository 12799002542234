.Chips {
  margin-bottom: 20px;

  .heading {
    margin: 0 0 5px 0;
  }

  .chip-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .chip {
    display: inline-flex;
    background-color: #eee;
    height: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 10px 15px;
    font-size: 14px;
    margin: 5px 5px 0 0;
  }

  .chip-delete {
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #b1b1b1;
    margin-left: 10px;
    color: #eee;

    &:hover {
      background-color: #888888;
    }
  }

  .chip-input {
    padding-top: 20px;
  }
}
