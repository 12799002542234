.PushDetails {
  height: 100%;
  display: flex;
  flex-direction: column;

  .MainHeader {
    justify-content: space-between;
  }

  .success-rate {
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div {
      display: flex;
      flex-direction: column;
      margin: 0 20px;
      min-width: 100px;
      justify-content: space-around;
      align-items: center;
    }

    .number {
      font-size: 33px;
      margin-bottom: 5px;
    }

    .text {
      font-size: 16px;
    }

    .delivered .number {
      color: #0ace79;
    }

    .meter .number {
      color: #0092ff;
    }

    .failed .number {
      color: #de4135;
    }
  }

  .blingring {
    border: 8px solid #d3d3d3;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-heading {
    margin-top: 20px;
  }

  .infobox {
    display: flex;
    background-color: white;
    padding: 20px;
    border: 1px solid #e7e9ed;
    border-radius: 5px;
    width: 80%;
    flex-wrap: wrap;
  }

  .delete-message-button {
    width: 200px;
    background-color: red;
    color: white;
  }

  .message-sent-content {
    .col {
      display: flex;
      align-items: center;
      width: 20%;
      margin: 0 0 10px 0;
      line-height: 16px;
    }

    .col2 {
      width: 80%;
      margin: 0 0 10px 0;
      line-height: 16px;
    }

    .message-icon {
      height: 50px;
    }

    .device-icon {
      height: 26px;
    }
  }
}
