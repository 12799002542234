html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 14px;
  color: #475c73;
}

a,
a:visited {
  cursor: pointer;
  text-decoration: none;
  color: #00a9ff;
}
a:hover {
  color: #00a9ff;
}

form,
input,
select,
textarea {
  font-size: 16px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}
textarea {
  margin-bottom: 20px;
}

textarea,
select,
input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #d3d3d3;
}

textarea:focus,
input:focus {
  border-bottom: 1px solid #00a9ff;
}

input:read-only {
  border: none;
}

input[type="submit"] {
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
}

::placeholder {
  font-style: italic;
}

#root {
  height: 100%;
}

* {
  outline: none;
}

a.button,
input.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  height: 40px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  color: #333c48;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  transition: all 0.2s;
  user-select: none;
  appearance: none;
  cursor: pointer;
  border: 1px solid #dfe3e9;
  background: #fff
    linear-gradient(
      to top,
      rgba(197, 208, 222, 0.08) 0,
      rgba(255, 255, 255, 0) 99%,
      rgba(255, 255, 255, 0) 100%
    );
}

table {
  border: 1px solid #e7e9ed;
  border-right: none;
  border-radius: 6px;
  background-color: #fff;
  border-collapse: separate;
}
thead {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
}
tr {
}
td {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  border-right: solid 1px rgba(0, 0, 0, 0.05);
  padding: 10px;
}

hr {
  border: none;
  border-bottom: 1px solid #caced8;
  margin: 10px 0;
}
