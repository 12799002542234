.Gravatar {
  height: 40px;
  width: 40px;
  margin: 0 20px 0 0;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
}
