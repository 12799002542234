.PushCampaigns {
  
  display: flex;
  flex-direction: column;
  .PD-table {
    .datetime {
      text-align: center;
      .date {
        margin-bottom: 5px;
      }
      .time {
        font-size: 12px;
        color: #b9b9b9;
      }
    }
  }
  .late-events {
    margin-bottom: 15px;
    font-style: italic;
    text-align: right;
  }
}

.PushCampaign {
  display: flex;
  flex-direction: column;
  height: 100%;

  .device-icon {
    width: 15px;
    color: #fff;
  }

  hr {
    width: 70px;
    margin: 40px 0;
  }

  .section-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .create-default-campaign-button {
    width: auto;
  }
  .campaign-preview {
    margin: 20px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;

    & > div {
      margin-bottom: 5px;
    }
  }

  .campaign-selector {
    display: flex;
    margin: 30px 0 20px 0;
  }

  .Select {
    margin: 0 20px 0 0;
  }

  .editable-cell {
    display: flex;
    width: 100%;
    align-items: center;
    white-space: normal;
    padding: 10px 0;
    font-size: 12px;
  }

  .audience-select {
    margin: 0;
    select {
      font-size: 10px;
    }
  }

  .remove-message-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  // Action buttons.
  .action-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .add-row-button {
    width: auto;
  }
  .publish-buttons {
    display: flex;
  }
  .publish-all-button {
    margin-left: 20px;
  }

  // This/next event info.
  .next-game-info {
    width: 50%;
  }

  .eventInfos {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .eventInfo {
    min-height: 120px;
    flex-direction: column;
  }

  .eventInfoItem {
    margin: 0 0 10px 0;
  }

  .PD-table.ReactTable {
    width: 100%;
    margin-bottom: 20px;

    .datetime {
      text-align: center;
      .date {
        margin-bottom: 5px;
      }
      .time {
        font-size: 12px;
        color: #b9b9b9;
      }
    }
  }
  .delete-campaign-button {
    width: 200px;
    background-color: red;
    color: white;
  }

  .show-hide-new-messages {
    width: 300px;    
  }
}

.PushCampaignSetup {
  display: flex;
  flex-direction: column;
  height: 100%;  

  hr {
    width: 70px;
    margin: 40px 0;
  }

  .section-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .create-default-campaign-button {
    width: auto;
  }
  .campaign-preview {
    margin: 20px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;

    & > div {
      margin-bottom: 5px;
    }
  }

  .campaign-selector {
    display: flex;
    margin: 30px 0 20px 0;
  }

  .Select {
    margin: 0 20px 0 0;
  }

  .editable-cell {
    display: flex;
    width: 100%;
    align-items: center;
    white-space: normal;
    padding: 10px 0;
    font-size: 12px;
    border: none;
  }

  .audience-select {
    margin: 0;
    select {
      font-size: 10px;
    }
  }

  .remove-message-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .warning-past-time {
    align-self: flex-end;
    color: red;
    margin-bottom: 15px;
  }

  // Action buttons.
  .action-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .add-row-button {
    width: auto;
  }
  .publish-buttons {
    display: flex;
  }
  .publish-all-button {
    margin-left: 20px;
  }

  // This/next event info.
  .next-game-info {
    width: 50%;
  }

  .eventInfos {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .eventInfo {
    min-height: 120px;
    flex-direction: column;
  }

  .eventInfoItem {
    margin: 0 0 10px 0;
  }

  .PD-table.ReactTable {
    width: 100%;
    margin-bottom: 20px;

    .datetime {
      text-align: center;
      .date {
        margin-bottom: 5px;
      }
      .time {
        font-size: 12px;
        color: #b9b9b9;
      }
    }
  }
}

.PushCampaignMessages {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; 
  margin-top: 20px;

  .Select {
    margin: 0 20px 0 0;
  }

  .editable-cell {
    display: flex;
    width: 100%;
    align-items: center;
    white-space: normal;
    padding: 10px 0;
    font-size: 12px;
    border: none;
  }

  .audience-select {
    margin: 0;
    select {
      font-size: 10px;
    }
  }

  .remove-message-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .warning-past-time {
    align-self: flex-end;
    color: red;
    margin-bottom: 15px;
  }

  // Action buttons.
  .action-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .add-row-button {
    width: auto;
  }
  .publish-buttons {
    display: flex;
  }
  .publish-all-button {
    margin-left: 20px;
  }  

  .PD-table.ReactTable {
    width: 100%;
    margin-bottom: 20px;

    .datetime {
      text-align: center;
      .date {
        margin-bottom: 5px;
      }
      .time {
        font-size: 12px;
        color: #b9b9b9;
      }
    }
  }
}
