.Scheduler {
  flex: 1;
  margin-bottom: 20px;

  .rdtActive {
    background-color: rgba(0, 169, 255, 0.5);
  }

  .rdtPrev,
  .rdtSwitch,
  .rdtNext,
  .rdtDay,
  .rdtBtn {
    cursor: pointer;
  }

  .send-now,
  .send-later {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .send-later {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;

    .date-picker {
      input {
        margin-bottom: 10px;
      }
      thead {
        box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.15);
        tr {
          height: 40px;
          margin-bottom: 10px;

          th {
            vertical-align: middle;
          }
        }
      }
      tbody {
        td {
          text-align: center;
          font-size: 12px;
          &.rdtDisabled {
            color: #c7c7c7;
            cursor: no-drop;
          }
        }
      }
    }

    .time-picker {
      input {
        margin-bottom: 10px;
      }
      table {
        width: 100%;
      }
      .rdtCounters {
        display: flex;
        justify-content: center;
      }
      .rdtCounter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30px;
        font-size: 16px;
        line-height: 20px;
      }
      .rdtCount {
        font-size: 12px;
      }
      .rdtCounterSeparator {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30px;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  .send-now-text {
    flex: 1;
  }

  .send-later-text {
    padding: 10px 0;
    width: 100%;
  }

  .Input {
    margin: 0;
  }
}
