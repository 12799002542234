.Accounts {
  height: 100%;
  display: flex;
  flex-direction: column;

  .team-name {
    margin: 5px 0;
  }

  .team-key-id {
    font-size: 12px;
    margin: 5px 0;
  }

  .app-id-key {
    margin: 5px 5px 5px 0;
  }

  .-totalPages {
    display: none;
  }
}
