.Account {
  height: 100%;
  display: flex;
  flex-direction: column;

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .account-form {
    width: 50%;
    min-width: 300px;
  }
}
