.Input {
  margin: 0 0 20px 0;

  input[type="radio"] {
    width: 30px;
    border: none;
  }
  input[type="checkbox"] {
    width: 50px;
    height: 30px;
    border: none;
    &:before {
      // background (red)
      position: relative;
      display: block;
      width: 100%;
      background-color: #fd4a4a;
      height: 100%;
      border: none;
      border-radius: 15px;
      content: "";
    }
    &:after {
      // switch
      display: block;
      position: relative;
      top: -27px;
      left: 5px;
      height: 24px;
      width: 24px;
      border-radius: 15px;
      background-color: #f1f1f1;
      border: none;
      content: "";
    }
    &:checked {
      &:before {
        // background (green)
        background-color: #53ce53;
      }
      &:after {
        // switch
        left: 20px;
      }
    }
  }

  label {
    &.disabled {
      opacity: 0.5;
    }
  }

  :disabled {
    opacity: 0.5;
    border-bottom-style: dashed;
  }

  .heading {
    margin: 0 0 5px 0;
  }

  .infotext {
    font-size: 10px;
    opacity: 0.5;
    margin-left: 40px;
  }
}

// A box typically surrounding multiple Inputs.
.input-box {
  border-radius: 4px;
  border: 1px solid #e7e9ed;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;

  a {
    color: #7b7f85;
    &:hover {
      color: #333c48;
    }
  }
}
