.PushActivity {
  display: flex;
  flex-direction: column;
  

  .device-icon {
    width: 15px;
    color: #fff;
  }

  .remove-message-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PD-table {
    .datetime {
      text-align: center;
      .date {
        margin-bottom: 5px;
      }
      .time {
        font-size: 12px;
        color: #b9b9b9;
      }
    }
    .-totalPages {
      display: none;
    }
  }
  .navigation {    
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 10px;

    &-prev {  
      width: 100px;                   
    }

    &-page {
      color: #444;
      font-size: 20px;
      flex: 1;
      text-align: center
    }

    &-next {
      width: 100px;
    }
     
  }
}
