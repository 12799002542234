.MainContent {
  height: 100%;
  padding: 20px;
  background-color: #f1f4f8;
  overflow-y: scroll;

  .section {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;

    .section-heading {
      font-size: 26px;
      font-weight: 300;
      margin-bottom: 20px;
      text-align: center;
      color: #47689f;
    }

    .section-subheading {
      font-size: 14px;
      color: #adafb2;
      margin: -10px 0 10px 0;
      text-align: center;
    }

    .section-content {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
      flex-shrink: 0;
    }
  }
}
