.Loading {
  width: 200px;
  height: 200px;
  margin: 20px;

  .loading-image {
    animation: spin 4s linear infinite;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
