.Main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  header,
  footer {
    display: flex;
    height: 70px;
    align-items: center;
    padding: 0 20px;
    color: #506270;
    border-bottom: 1px solid #e0e0e0;
    flex-shrink: 0;
    justify-content: space-between;
  }
}
