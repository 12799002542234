/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 14px;
  color: #475c73;
}

a,
a:visited {
  cursor: pointer;
  text-decoration: none;
  color: #00a9ff;
}
a:hover {
  color: #00a9ff;
}

form,
input,
select,
textarea {
  font-size: 16px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}
textarea {
  margin-bottom: 20px;
}

textarea,
select,
input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #d3d3d3;
}

textarea:focus,
input:focus {
  border-bottom: 1px solid #00a9ff;
}

input:-moz-read-only {
  border: none;
}

input:read-only {
  border: none;
}

input[type="submit"] {
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

::-ms-input-placeholder {
  font-style: italic;
}

::placeholder {
  font-style: italic;
}

#root {
  height: 100%;
}

* {
  outline: none;
}

a.button,
input.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  height: 40px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  color: #333c48;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  transition: all 0.2s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  border: 1px solid #dfe3e9;
  background: #fff
    linear-gradient(
      to top,
      rgba(197, 208, 222, 0.08) 0,
      rgba(255, 255, 255, 0) 99%,
      rgba(255, 255, 255, 0) 100%
    );
}

table {
  border: 1px solid #e7e9ed;
  border-right: none;
  border-radius: 6px;
  background-color: #fff;
  border-collapse: separate;
}
thead {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
}
tr {
}
td {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  border-right: solid 1px rgba(0, 0, 0, 0.05);
  padding: 10px;
}

hr {
  border: none;
  border-bottom: 1px solid #caced8;
  margin: 10px 0;
}

.Input {
  margin: 0 0 20px 0; }
  .Input input[type="radio"] {
    width: 30px;
    border: none; }
  .Input input[type="checkbox"] {
    width: 50px;
    height: 30px;
    border: none; }
    .Input input[type="checkbox"]:before {
      position: relative;
      display: block;
      width: 100%;
      background-color: #fd4a4a;
      height: 100%;
      border: none;
      border-radius: 15px;
      content: ""; }
    .Input input[type="checkbox"]:after {
      display: block;
      position: relative;
      top: -27px;
      left: 5px;
      height: 24px;
      width: 24px;
      border-radius: 15px;
      background-color: #f1f1f1;
      border: none;
      content: ""; }
    .Input input[type="checkbox"]:checked:before {
      background-color: #53ce53; }
    .Input input[type="checkbox"]:checked:after {
      left: 20px; }
  .Input label.disabled {
    opacity: 0.5; }
  .Input :disabled {
    opacity: 0.5;
    border-bottom-style: dashed; }
  .Input .heading {
    margin: 0 0 5px 0; }
  .Input .infotext {
    font-size: 10px;
    opacity: 0.5;
    margin-left: 40px; }

.input-box {
  border-radius: 4px;
  border: 1px solid #e7e9ed;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px; }
  .input-box a {
    color: #7b7f85; }
    .input-box a:hover {
      color: #333c48; }

.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #35557f; }
  .Login .input-box {
    padding: 40px;
    padding-bottom: 20px; }
  .Login .logo {
    font-size: 22px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px; }
  .Login .error-message {
    text-align: center;
    height: 20px;
    line-height: 20px;
    color: #d42e2e;
    visibility: hidden; }
    .Login .error-message.visible {
      visibility: visible; }

.Gravatar {
  height: 40px;
  width: 40px;
  margin: 0 20px 0 0; }
  .Gravatar img {
    height: 100%;
    width: 100%;
    border-radius: 50%; }

.Sidebar {
  display: flex;
  flex-direction: column;
  width: 250px;
  background-color: #2b333e;
  transition: 1s;
  color: #ffffff99;
  justify-content: space-between;
  flex-shrink: 0; }
  .Sidebar header,
  .Sidebar footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 70px;
    background-color: #333c47; }
  .Sidebar header h1 {
    font-size: 30px; }
  .Sidebar .menu {
    flex-grow: 1;
    font-size: 14px; }
  .Sidebar .menu-section {
    display: block;
    border-left: 3px solid transparent;
    padding: 10px; }
    .Sidebar .menu-section.active {
      border-left: 3px solid #00a9ff; }
    .Sidebar .menu-section.admin-menu {
      display: none; }
      .Sidebar .menu-section.admin-menu.visible {
        display: block; }
  .Sidebar .menu-heading {
    display: block;
    padding: 10px;
    color: #7b7f85; }
  .Sidebar .menu-items {
    padding: 0 10px;
    list-style: none;
    margin: 0; }
  .Sidebar .menu-item {
    display: block;
    padding: 10px;
    color: #7b7f85;
    /*#c5cfde*/ }
  .Sidebar .active-link {
    color: #fff; }
  .Sidebar footer {
    cursor: pointer; }
    .Sidebar footer .email {
      color: #d6d8da; }
      .Sidebar footer .email:hover {
        color: #fff; }
    .Sidebar footer .user-menu {
      padding: 10px 0;
      display: none;
      width: 220px;
      position: absolute;
      bottom: 65px;
      margin: 0; }
      .Sidebar footer .user-menu.visible {
        display: block; }
      .Sidebar footer .user-menu .user-menu-li a {
        display: block;
        padding: 10px; }
    .Sidebar footer .arrow {
      position: absolute;
      bottom: -30px;
      left: calc(50% - 20px);
      height: 0;
      width: 0;
      border: 20px solid transparent;
      border-top: 10px solid #fff; }

.Main {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }
  .Main header,
  .Main footer {
    display: flex;
    height: 70px;
    align-items: center;
    padding: 0 20px;
    color: #506270;
    border-bottom: 1px solid #e0e0e0;
    flex-shrink: 0;
    justify-content: space-between; }

.MainHeader h1 {
  font-size: 18px; }

.MainContent {
  height: 100%;
  padding: 20px;
  background-color: #f1f4f8;
  overflow-y: scroll; }
  .MainContent .section {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center; }
    .MainContent .section .section-heading {
      font-size: 26px;
      font-weight: 300;
      margin-bottom: 20px;
      text-align: center;
      color: #47689f; }
    .MainContent .section .section-subheading {
      font-size: 14px;
      color: #adafb2;
      margin: -10px 0 10px 0;
      text-align: center; }
    .MainContent .section .section-content {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
      flex-shrink: 0; }

.Snackbar {
  min-width: 500px;
  display: flex;
  position: absolute;
  bottom: 0px;
  height: 50px;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: #333c46;
  color: #fff; }

.Chips {
  margin-bottom: 20px; }
  .Chips .heading {
    margin: 0 0 5px 0; }
  .Chips .chip-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .Chips .chip {
    display: inline-flex;
    background-color: #eee;
    height: 10px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 10px 15px;
    font-size: 14px;
    margin: 5px 5px 0 0; }
  .Chips .chip-delete {
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #b1b1b1;
    margin-left: 10px;
    color: #eee; }
    .Chips .chip-delete:hover {
      background-color: #888888; }
  .Chips .chip-input {
    padding-top: 20px; }

.Loading {
  width: 200px;
  height: 200px;
  margin: 20px; }
  .Loading .loading-image {
    -webkit-animation: spin 4s linear infinite;
            animation: spin 4s linear infinite; }

@-webkit-keyframes spin {
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

.MyAccount {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .MyAccount .section-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .MyAccount .account-form {
    width: 50%;
    min-width: 300px; }

.Select {
  margin: 0 0 20px 0; }
  .Select .heading {
    margin: 0 0 10px 0; }

.Account {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .Account .section-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .Account .account-form {
    width: 50%;
    min-width: 300px; }

.Accounts {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .Accounts .team-name {
    margin: 5px 0; }
  .Accounts .team-key-id {
    font-size: 12px;
    margin: 5px 0; }
  .Accounts .app-id-key {
    margin: 5px 5px 5px 0; }
  .Accounts .-totalPages {
    display: none; }

.PushActivity {
  display: flex;
  flex-direction: column; }
  .PushActivity .device-icon {
    width: 15px;
    color: #fff; }
  .PushActivity .remove-message-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .PushActivity .PD-table .datetime {
    text-align: center; }
    .PushActivity .PD-table .datetime .date {
      margin-bottom: 5px; }
    .PushActivity .PD-table .datetime .time {
      font-size: 12px;
      color: #b9b9b9; }
  .PushActivity .PD-table .-totalPages {
    display: none; }
  .PushActivity .navigation {
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 10px; }
    .PushActivity .navigation-prev {
      width: 100px; }
    .PushActivity .navigation-page {
      color: #444;
      font-size: 20px;
      flex: 1 1;
      text-align: center; }
    .PushActivity .navigation-next {
      width: 100px; }

.PushDetails {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .PushDetails .MainHeader {
    justify-content: space-between; }
  .PushDetails .success-rate {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .PushDetails .success-rate > div {
      display: flex;
      flex-direction: column;
      margin: 0 20px;
      min-width: 100px;
      justify-content: space-around;
      align-items: center; }
    .PushDetails .success-rate .number {
      font-size: 33px;
      margin-bottom: 5px; }
    .PushDetails .success-rate .text {
      font-size: 16px; }
    .PushDetails .success-rate .delivered .number {
      color: #0ace79; }
    .PushDetails .success-rate .meter .number {
      color: #0092ff; }
    .PushDetails .success-rate .failed .number {
      color: #de4135; }
  .PushDetails .blingring {
    border: 8px solid #d3d3d3;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .PushDetails .section-heading {
    margin-top: 20px; }
  .PushDetails .infobox {
    display: flex;
    background-color: white;
    padding: 20px;
    border: 1px solid #e7e9ed;
    border-radius: 5px;
    width: 80%;
    flex-wrap: wrap; }
  .PushDetails .delete-message-button {
    width: 200px;
    background-color: red;
    color: white; }
  .PushDetails .message-sent-content .col {
    display: flex;
    align-items: center;
    width: 20%;
    margin: 0 0 10px 0;
    line-height: 16px; }
  .PushDetails .message-sent-content .col2 {
    width: 80%;
    margin: 0 0 10px 0;
    line-height: 16px; }
  .PushDetails .message-sent-content .message-icon {
    height: 50px; }
  .PushDetails .message-sent-content .device-icon {
    height: 26px; }

.Scheduler {
  flex: 1 1;
  margin-bottom: 20px; }
  .Scheduler .rdtActive {
    background-color: rgba(0, 169, 255, 0.5); }
  .Scheduler .rdtPrev,
  .Scheduler .rdtSwitch,
  .Scheduler .rdtNext,
  .Scheduler .rdtDay,
  .Scheduler .rdtBtn {
    cursor: pointer; }
  .Scheduler .send-now,
  .Scheduler .send-later {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .Scheduler .send-later {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around; }
    .Scheduler .send-later .date-picker input {
      margin-bottom: 10px; }
    .Scheduler .send-later .date-picker thead {
      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.15); }
      .Scheduler .send-later .date-picker thead tr {
        height: 40px;
        margin-bottom: 10px; }
        .Scheduler .send-later .date-picker thead tr th {
          vertical-align: middle; }
    .Scheduler .send-later .date-picker tbody td {
      text-align: center;
      font-size: 12px; }
      .Scheduler .send-later .date-picker tbody td.rdtDisabled {
        color: #c7c7c7;
        cursor: no-drop; }
    .Scheduler .send-later .time-picker input {
      margin-bottom: 10px; }
    .Scheduler .send-later .time-picker table {
      width: 100%; }
    .Scheduler .send-later .time-picker .rdtCounters {
      display: flex;
      justify-content: center; }
    .Scheduler .send-later .time-picker .rdtCounter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 30px;
      font-size: 16px;
      line-height: 20px; }
    .Scheduler .send-later .time-picker .rdtCount {
      font-size: 12px; }
    .Scheduler .send-later .time-picker .rdtCounterSeparator {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 30px;
      font-size: 20px;
      line-height: 24px; }
  .Scheduler .send-now-text {
    flex: 1 1; }
  .Scheduler .send-later-text {
    padding: 10px 0;
    width: 100%; }
  .Scheduler .Input {
    margin: 0; }

.Clock {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  width: 100%;
  min-height: 50px;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 100px; }
  .Clock .time {
    font-size: 60px;
    margin-bottom: 20px;
    font-weight: 100; }
  .Clock .date {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 100; }

.PushSend {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .PushSend .content {
    display: flex; }
  .PushSend .preview {
    flex-basis: 33%;
    border-right: 1px solid #caced8; }
    .PushSend .preview .section-content {
      position: -webkit-sticky;
      position: sticky;
      top: 20px; }
  .PushSend .phone {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 20px;
    width: 320px;
    height: 600px;
    padding: 0 10px 20px;
    background-color: #fff; }
  .PushSend .phone-speaker {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px; }
    .PushSend .phone-speaker div {
      border: 2px solid black;
      width: 40px;
      border-radius: 10px; }
  .PushSend .phone-display {
    display: flex;
    position: relative;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: start;
    width: 96%;
    height: 90%;
    border: 1px solid black;
    padding: 0px;
    background-color: #fff;
    word-wrap: break-word;
    line-height: 20px;
    font-size: 14px; }
  .PushSend .phone-display-background {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: fill;
    align-items: center; }
  .PushSend .phone-message {
    border-radius: 15px;
    z-index: 10;
    width: 90%;
    min-height: 80px;
    color: #fff;
    padding: 10px 15px;
    background-color: rgba(231, 211, 211, 0.738);
    box-sizing: border-box;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-size: 14px; }
    .PushSend .phone-message-header {
      display: flex;
      align-items: center;
      height: 20px; }
      .PushSend .phone-message-header-appname {
        flex: 1 1;
        color: #444; }
      .PushSend .phone-message-header-time {
        color: #444;
        font-size: 13px; }
      .PushSend .phone-message-header-icon {
        width: 20px;
        margin-right: 10px; }
    .PushSend .phone-message-title {
      margin-top: 5px;
      font-weight: bold;
      color: #333; }
    .PushSend .phone-message-body {
      margin-top: 2px;
      color: #444; }
  .PushSend .phone-message-icon {
    width: 20px;
    float: left;
    margin-right: 10px;
    align-content: center; }
  .PushSend .phone-message-link {
    font-size: 10px;
    text-decoration: underline;
    color: #fff; }
  .PushSend .message {
    flex-grow: 1; }
  .PushSend .write-message {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center; }
  .PushSend .recipients {
    width: 100%; }
    .PushSend .recipients .channel-list {
      width: 100%; }

.PushCampaigns {
  display: flex;
  flex-direction: column; }
  .PushCampaigns .PD-table .datetime {
    text-align: center; }
    .PushCampaigns .PD-table .datetime .date {
      margin-bottom: 5px; }
    .PushCampaigns .PD-table .datetime .time {
      font-size: 12px;
      color: #b9b9b9; }
  .PushCampaigns .late-events {
    margin-bottom: 15px;
    font-style: italic;
    text-align: right; }

.PushCampaign {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .PushCampaign .device-icon {
    width: 15px;
    color: #fff; }
  .PushCampaign hr {
    width: 70px;
    margin: 40px 0; }
  .PushCampaign .section-content {
    display: flex;
    align-items: center;
    flex-direction: column; }
  .PushCampaign .create-default-campaign-button {
    width: auto; }
  .PushCampaign .campaign-preview {
    margin: 20px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px; }
    .PushCampaign .campaign-preview > div {
      margin-bottom: 5px; }
  .PushCampaign .campaign-selector {
    display: flex;
    margin: 30px 0 20px 0; }
  .PushCampaign .Select {
    margin: 0 20px 0 0; }
  .PushCampaign .editable-cell {
    display: flex;
    width: 100%;
    align-items: center;
    white-space: normal;
    padding: 10px 0;
    font-size: 12px; }
  .PushCampaign .audience-select {
    margin: 0; }
    .PushCampaign .audience-select select {
      font-size: 10px; }
  .PushCampaign .remove-message-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
  .PushCampaign .action-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px; }
  .PushCampaign .add-row-button {
    width: auto; }
  .PushCampaign .publish-buttons {
    display: flex; }
  .PushCampaign .publish-all-button {
    margin-left: 20px; }
  .PushCampaign .next-game-info {
    width: 50%; }
  .PushCampaign .eventInfos {
    display: flex;
    width: 100%;
    justify-content: center; }
  .PushCampaign .eventInfo {
    min-height: 120px;
    flex-direction: column; }
  .PushCampaign .eventInfoItem {
    margin: 0 0 10px 0; }
  .PushCampaign .PD-table.ReactTable {
    width: 100%;
    margin-bottom: 20px; }
    .PushCampaign .PD-table.ReactTable .datetime {
      text-align: center; }
      .PushCampaign .PD-table.ReactTable .datetime .date {
        margin-bottom: 5px; }
      .PushCampaign .PD-table.ReactTable .datetime .time {
        font-size: 12px;
        color: #b9b9b9; }
  .PushCampaign .delete-campaign-button {
    width: 200px;
    background-color: red;
    color: white; }
  .PushCampaign .show-hide-new-messages {
    width: 300px; }

.PushCampaignSetup {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .PushCampaignSetup hr {
    width: 70px;
    margin: 40px 0; }
  .PushCampaignSetup .section-content {
    display: flex;
    align-items: center;
    flex-direction: column; }
  .PushCampaignSetup .create-default-campaign-button {
    width: auto; }
  .PushCampaignSetup .campaign-preview {
    margin: 20px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px; }
    .PushCampaignSetup .campaign-preview > div {
      margin-bottom: 5px; }
  .PushCampaignSetup .campaign-selector {
    display: flex;
    margin: 30px 0 20px 0; }
  .PushCampaignSetup .Select {
    margin: 0 20px 0 0; }
  .PushCampaignSetup .editable-cell {
    display: flex;
    width: 100%;
    align-items: center;
    white-space: normal;
    padding: 10px 0;
    font-size: 12px;
    border: none; }
  .PushCampaignSetup .audience-select {
    margin: 0; }
    .PushCampaignSetup .audience-select select {
      font-size: 10px; }
  .PushCampaignSetup .remove-message-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
  .PushCampaignSetup .warning-past-time {
    align-self: flex-end;
    color: red;
    margin-bottom: 15px; }
  .PushCampaignSetup .action-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px; }
  .PushCampaignSetup .add-row-button {
    width: auto; }
  .PushCampaignSetup .publish-buttons {
    display: flex; }
  .PushCampaignSetup .publish-all-button {
    margin-left: 20px; }
  .PushCampaignSetup .next-game-info {
    width: 50%; }
  .PushCampaignSetup .eventInfos {
    display: flex;
    width: 100%;
    justify-content: center; }
  .PushCampaignSetup .eventInfo {
    min-height: 120px;
    flex-direction: column; }
  .PushCampaignSetup .eventInfoItem {
    margin: 0 0 10px 0; }
  .PushCampaignSetup .PD-table.ReactTable {
    width: 100%;
    margin-bottom: 20px; }
    .PushCampaignSetup .PD-table.ReactTable .datetime {
      text-align: center; }
      .PushCampaignSetup .PD-table.ReactTable .datetime .date {
        margin-bottom: 5px; }
      .PushCampaignSetup .PD-table.ReactTable .datetime .time {
        font-size: 12px;
        color: #b9b9b9; }

.PushCampaignMessages {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 20px; }
  .PushCampaignMessages .Select {
    margin: 0 20px 0 0; }
  .PushCampaignMessages .editable-cell {
    display: flex;
    width: 100%;
    align-items: center;
    white-space: normal;
    padding: 10px 0;
    font-size: 12px;
    border: none; }
  .PushCampaignMessages .audience-select {
    margin: 0; }
    .PushCampaignMessages .audience-select select {
      font-size: 10px; }
  .PushCampaignMessages .remove-message-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
  .PushCampaignMessages .warning-past-time {
    align-self: flex-end;
    color: red;
    margin-bottom: 15px; }
  .PushCampaignMessages .action-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px; }
  .PushCampaignMessages .add-row-button {
    width: auto; }
  .PushCampaignMessages .publish-buttons {
    display: flex; }
  .PushCampaignMessages .publish-all-button {
    margin-left: 20px; }
  .PushCampaignMessages .PD-table.ReactTable {
    width: 100%;
    margin-bottom: 20px; }
    .PushCampaignMessages .PD-table.ReactTable .datetime {
      text-align: center; }
      .PushCampaignMessages .PD-table.ReactTable .datetime .date {
        margin-bottom: 5px; }
      .PushCampaignMessages .PD-table.ReactTable .datetime .time {
        font-size: 12px;
        color: #b9b9b9; }

.Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); }
  .Modal .message {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border: 1px solid #e7e9ed;
    border-radius: 5px;
    width: 50%;
    min-height: 100px; }
  .Modal .Loading {
    width: auto;
    height: auto; }

.Layout {
  display: flex;
  flex-direction: row;
  width: 100%; }

.App {
  display: flex;
  height: 100%; }
  .App .App-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 30px;
    color: #455867; }
  .App .hidden {
    display: none; }

.infobox {
  display: flex;
  background-color: white;
  padding: 20px;
  border: 1px solid #e7e9ed;
  border-radius: 5px;
  width: 80%;
  flex-wrap: wrap; }

.PD-table.ReactTable {
  height: 100%;
  border: 1px solid #e7e9ed;
  border-radius: 6px;
  background-color: #fff; }
  .PD-table.ReactTable .rt-thead {
    flex-grow: 0; }
    .PD-table.ReactTable .rt-thead .rt-th {
      padding: 10px 15px; }
    .PD-table.ReactTable .rt-thead .rt-tr {
      text-align: left; }
  .PD-table.ReactTable .rt-tbody {
    flex-grow: 0; }
  .PD-table.ReactTable .rt-tr {
    min-height: 40px; }
  .PD-table.ReactTable .rt-tr-group {
    min-height: 40px;
    cursor: pointer; }
  .PD-table.ReactTable .rt-td {
    display: flex;
    align-items: center;
    padding: 0 15px; }

