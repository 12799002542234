.Clock {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  width: 100%;
  min-height: 50px;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 100px;

  .time {
    font-size: 60px;
    margin-bottom: 20px;
    font-weight: 100;
  }
  .date {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 100;
  }
}
