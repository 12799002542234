.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #35557f;

  .input-box {
    padding: 40px;
    padding-bottom: 20px;
  }

  .logo {
    font-size: 22px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
  }

  .error-message {
    text-align: center;
    height: 20px;
    line-height: 20px;
    color: #d42e2e;
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }
}
