.Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);

  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border: 1px solid #e7e9ed;
    border-radius: 5px;
    width: 50%;
    min-height: 100px;
  }

  .Loading {
    width: auto;
    height: auto;
  }
}
